import { createAvatar } from "@dicebear/core";
import { shapes } from "@dicebear/collection";

export function getAvatarUrl(seed?: string) {
  const modifiedSeed = seed?.replace(/[^A-Za-z0-9 ]/g, "").replace(/ /g, "") ?? "default";

  const avatar = createAvatar(shapes, {
    seed: modifiedSeed,
  });

  return avatar.toDataUriSync();
}
