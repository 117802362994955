import type {
  DcEndPoints,
  RequestBase,
  ResponseBase,
  ResponseWrapper,
} from "docuchatcommontypes";

export async function useApi<T extends RequestBase, U extends ResponseBase>(
  endpoint: DcEndPoints.EndPoint<T, U>,
): Promise<ResponseWrapper<U>> {
  const { $sentry } = useNuxtApp();
  const apiBaseUrl = useRuntimeConfig().public.apiUrl;

  return await $fetch<ResponseWrapper<U>>(`${apiBaseUrl}/${endpoint.fullRoute}`, {
    body: endpoint.request,
    method: endpoint.method,
    credentials: "include",
    headers: await getHeaders(),
    retry: 3,
    retryDelay: 500,

    onRequestError(context) {
      $sentry.captureError(context.error, {
        extra: {
          options: context.options,
          request: context.request,
          response: context.response,
        },
      });
    },

    onResponseError(context) {
      $sentry.captureError(context.error, {
        extra: {
          options: context.options,
          request: context.request,
          response: context.response,
        },
      });
    },
  });
}

export async function useApiStream<T extends RequestBase, U extends ResponseBase>(
  endpoint: DcEndPoints.EndPoint<T, U>,
): Promise<Response> {
  const apiBaseUrl = useRuntimeConfig().public.apiUrl;
  const headers = await getHeaders();
  headers["Content-Type"] = "application/json";

  // $fetch causes issues in streams so we use usual fetch
  return await fetch(`${apiBaseUrl}/${endpoint.fullRoute}`, {
    method: endpoint.method,
    credentials: "include",
    body: JSON.stringify(endpoint.request),
    headers,
  });
}

async function getHeaders() {
  const headers: HeadersInit = {
    Origin: useRuntimeConfig().public.appUrl,
  };

  // We include the token in the headers if the user is authenticated
  const includeAuthToken = useUserStore().isAuthenticated;
  if (includeAuthToken) {
    const reqHeaders = useRequestHeaders(["cookie"]) as HeadersInit;
    const token = await $fetch("/api/token", { headers: reqHeaders });
    headers.Authorization = `Bearer ${token}`;
  }

  return headers;
}
